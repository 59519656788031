import React from "react";
import "../css/about.css";
import Navbar from "../components/navbar";
import About from "../components/about";
import MetaData from "../components/MetaData";
import AboutHeader from "../components/aboutHeader";
import BehindMind from "../components/behindMind";
import ItSolution from "../components/itSolution";
import ChairmanStatment from "../components/chairmanStatment";
import GetHelp from "../components/getHelp";
import Footer from "../components/footer";

const AboutUs = () => {
  return (
    <div className="aboutUs">
      <MetaData title="92 Logics - About Us" />
      <Navbar />
      <AboutHeader />
      <BehindMind />
      <ItSolution />
      <ChairmanStatment />
      <About />
      <GetHelp />
      <Footer />
    </div>
  );
};

export default AboutUs;
