import React from "react";
import "../css/technologies.css";

const Technologies = () => {
  return (
    <div className="technologies">
      <div className="technologies_title">
        Technologies <b>We Use</b>
      </div>
      <div className="technologies_desc">
        Our skills extend to convert existing applications into React or Blazor
        applications and we are specialize in taking projects from the
        requirement gathering phase to deployment of fully tested applications.
        We're proud to be top-level database designers and optimization experts.
        We offer consultancy services sql and nosql databases & reports.
      </div>
      <div className="technologys">
        <div className="technology">
          <div className="technology_img">
            <img src="/assets/dotnet.png" alt="dotnet" />
          </div>
        </div>
        <div className="technology" style={{ height: "42px" }}>
          <div className="technology_img">
            <img src="/assets/aspnet.png" alt="aspnet" width="110" />
          </div>
        </div>
        <div className="technology" style={{ height: "42px" }}>
          <div className="technology_img">
            <img src="/assets/aspnetmvc.png" alt="aspnetmvc" width="100" />
          </div>
        </div>
        <div className="technology" style={{ height: "42px" }}>
          <div className="technology_img">
            <img src="/assets/aspnetzero.png" alt="aspnetzero" width="70" />
          </div>
        </div>
        <div className="technology" style={{ height: "42px" }}>
          <div className="technology_img">
            <img src="/assets/aspnetboler.png" alt="aspnetboler" width="70" />
          </div>
        </div>
        <div className="technology" style={{ height: "45px" }}>
          <div className="technology_img">
            <img src="/assets/blazor.png" alt="blazor" width="100" />
          </div>
        </div>
        <div className="technology">
          <div className="technology_img">
            <img src="/assets/react.png" alt="react" />
          </div>
        </div>
        <div className="technology">
          <div className="technology_img">
            <img src="/assets/angular.png" alt="angular" />
          </div>
        </div>
        <div className="technology">
          <div className="technology_img">
            <img src="/assets/js.png" alt="js" />
          </div>
        </div>

        <div className="technology">
          <div className="technology_img">
            <img src="/assets/nodejs.png" alt="nodejs" />
          </div>
        </div>
        <div className="technology">
          <div className="technology_img">
            <img src="/assets/python.png" alt="python" />
          </div>
        </div>
        <div className="technology">
          <div className="technology_img">
            <img src="/assets/django.png" alt="django" />
          </div>
        </div>

        <div className="technology">
          <div className="technology_img">
            <img src="/assets/machinelearning.png" alt="machinelearning" />
          </div>
        </div>
        <div className="technology">
          <div className="technology_img">
            <img src="/assets/datascience.png" alt="datascience" />
          </div>
        </div>
        <div className="technology">
          <div className="technology_img">
            <img src="/assets/ai.png" alt="ai" />
          </div>
        </div>

        <div className="technology">
          <div className="technology_img">
            <img src="/assets/db.png" alt="mongodb" />
          </div>
        </div>
        <div className="technology">
          <div className="technology_img">
            <img src="/assets/mysql.png" alt="mysql" />
          </div>
        </div>
        <div className="technology" style={{ height: "45px" }}>
          <div className="technology_img">
            <img src="/assets/sqlserver.png" alt="sqlserver" width="80" />
          </div>
        </div>
        <div className="technology">
          <div className="technology_img">
            <img src="/assets/android.png" alt="android" />
          </div>
        </div>
        <div className="technology">
          <div className="technology_img">
            <img src="/assets/reactnative.png" alt="reactnative" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technologies;
