import React from "react";
import "../css/dotNetDevelopersAvailable.css";

const DigitalDotNetDevelopersAvailable = () => {
  const employeeData = [
    {
      id: 1,
      name: "Qaiser Mehmood",
      role: "Senior Digital Marketing Manager",
      message:
        "With 10+ years of experience as a Senior Digital Marketing Manager.",
      image: "/assets/ceo.png",
      skills: [
        "Digital Marketing Strategy",
        "SEO",
        "SEM",
        "Social Media Management",
      ],
    },
    {
      id: 2,
      name: "Haider Raza",
      role: "Digital Marketing Manager",
      message:
        "With 4+ years of experience as a Senior Digital Marketing Manager.",
      image: "/assets/randomUser.png",
      skills: [
        "Digital Marketing Strategy",
        "SEO",
        "SEM",
        "Social Media Management",
      ],
    },
    {
      id: 3,
      name: "Muhammad Mohsin",
      role: "Digital Marketing Manager",
      message:
        "With 6+ years of experience as a Senior Digital Marketing Manager.",
      image: "/assets/mohsin2.png",
      skills: [
        "Digital Marketing Strategy",
        "SEO",
        "SEM",
        "Social Media Management",
      ],
    },
    {
      id: 4,
      name: "Hamza Fakhar",
      role: "Digital Marketing Manager",
      message:
        "With 6+ years of experience as a Senior Digital Marketing Manager.",
      image: "/assets/hamza2.png",
      skills: [
        "Digital Marketing Strategy",
        "SEO",
        "SEM",
        "Social Media Management",
      ],
    },
  ];

  return (
    <div className="dotNetDevelopersAvailable">
      <div className="dotNetDevelopersAvailable_title">
        <div>
          Digital Marketing Managers <b>Currently Available</b>
        </div>
        <p className="dotNetDevelopersAvailable_paragraph">
          If you are planning to hire a Digital Marketing Managers in terms of
          marketing, then it’s your best choice to deal with. We acquire
          market-competitive resources who deliver qualitative products on time
          with complete training for certain jobs. Your business will likely
          suffer more as a result of such an approach.
        </p>
      </div>
      <div className="dotNetDevelopersAvailable_content">
        <div className="dotNetDevelopersAvailable_employees">
          {employeeData.map((employee) => (
            <div
              className="dotNetDevelopersAvailable_single_emp"
              key={employee.id}
            >
              <div className="dotNetDevelopersAvailable_single_emp_img">
                <img src={employee.image} alt={employee.name} />
              </div>
              <h2>{employee.name}</h2>
              <p>{employee.role}</p>
              <div className="span">{employee.message}</div>
              <div className="dotNetDevelopersAvailable_single_emp_skills_list">
                {employee.skills.map((skill, index) => (
                  <span key={index}>{skill}</span>
                ))}
              </div>
              <div className="dotNetDevelopersAvailable_employee_hire_btn">
                <a href="#">Hire Now</a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DigitalDotNetDevelopersAvailable;
