import React from "react";
import "../css/budgetOffers.css";
import { Link } from "react-router-dom";

const BudgetOffers = () => {
  return (
    <div className="BudgetOffers">
      <div className="left_side_offer_image">
        <img src="/assets/Software-Engineers-on-demand.webp" alt="budget" />
      </div>
      <div className="right_side_offer_content">
        <h1 className="offer_title">We offer in-budget Prices</h1>
        <p className="offer_desc">
          Get to know about all the pricing model's detail as we are just a
          click away!
        </p>
        <div className="offer_btn">
          <Link to="/hire" className="offer_btn_s">
            Hire a Developer
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BudgetOffers;
