import React, { useState } from "react";
import "../css/faqs.css";
import { AiFillCaretDown } from "react-icons/ai";

const Faqs = () => {
  const faqs = [
    {
      question: "In what ways do 92 Logics ensure transparency?",
      answer:
        "Our agile project management system utilizes 100% online cloud-based tools to ensure continuous visibility and delivery to you.",
    },
    {
      question: "My full-time resource will only be working for me, correct?",
      answer: "Yes, that is correct. Dedicated development is what we offer.",
    },
    {
      question: "Can I interview the resources before hiring them?",
      answer:
        "The majority of our clients ask to interview our software engineers. It is absolutely fine for you to interview them. You will get the resource of your choice.",
    },
    {
      question:
        "Is it possible to hire resources with multiple skills/experiences?",
      answer:
        "Of course! It is possible to hire remote teams with various skills and experiences. It is entirely up to you how you make your team.",
    },
    {
      question: "Can I stay in touch with my team?",
      answer:
        "We work by your time zone, and software engineers are readily available whenever you need them. Thanks to our top project management tools, you will always be in touch with them.",
    },
    {
      question: "Does the hourly rate apply to full-time resources?",
      answer:
        "In 92 Logics, different models are available. Resources can be hired full-time, part-time, hourly, or on a project. Based on your chosen model, you pay for the resources.",
    },
  ];
  const [isOpen, setIsOpen] = useState(new Array(faqs.length).fill(false));

  const toggleFAQ = (index) => {
    const newIsOpen = [...isOpen];
    newIsOpen[index] = !newIsOpen[index];
    setIsOpen(newIsOpen);
  };
  return (
    <div className="faqs">
      <div className="faqs_title">
        You Probably <b>Have Questions</b>
      </div>
      <div className="faqs_desc">
        92 Logics is a trusted software development company that provides
        reliable outsourcing services to businesses worldwide. Our skilled
        developers have years of experience in building custom software
        solutions for a wide range of industries. We understand the importance
        of delivering quality work in time and within budget, which is why we
        offer cost-effective outsourcing services without compromising on
        quality. Whether you need a web application, mobile app or any other
        software solution, our developers can help you bring your ideas to life.
        Contact us today to learn more about our outsourcing services and how we
        can help your business succeed.
      </div>
      <ul className="faqs_list">
        {faqs.map((faq, index) => (
          <li key={index} className="faq-item">
            <div className="faq-header" onClick={() => toggleFAQ(index)}>
              <h3>{faq.question}</h3>
              <span className={`arrow ${isOpen[index] ? "open" : ""}`}>
                <AiFillCaretDown />
              </span>
            </div>

            {isOpen[index] && <p>{faq.answer}</p>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Faqs;
