import React from "react";
import "../css/about.css";
import about1 from "./../svgs/about1.svg";
import about2 from "./../svgs/about2.svg";
import about3 from "./../svgs/about3.svg";
import about4 from "./../svgs/about4.svg";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="about_page">
      <div className="about_title">
        Why Work <b> With Us</b>
      </div>
      <div className="about_desc">
        We are known for our quality and work ethics among our clients. The
        three core values that guide our teams are dedication, motivation and
        long-term client relationships.
      </div>
      <div className="about_page_items">
        <div className="about_page_item">
          <div className="about_page_item_icon">
            <img src={about1} alt="about Image" />
          </div>
          <div className="about_page_item_text">
            <b>18+ Years of Experience</b>
            <p>
              Our software developers specialize in software development, web,
              Desktop, mobile, SQA, software maintenance, data science, UI/UX
              design, AI & ML, ERP & CRM, design thinking and business
              intelligence.
            </p>
          </div>
        </div>
        <div className="about_page_item">
          <div className="about_page_item_icon">
            <img src={about2} alt="about2" />
          </div>
          <div className="about_page_item_text">
            <b>Agile Practitioner</b>
            <p>
              Instead of relying on a "big bang" launch, our software engineers
              work with scrum masters to deliver work in small but consumable
              increments. As a result, we help you deliver value faster and more
              efficiently.
            </p>
          </div>
        </div>
        <div className="about_page_item">
          <div className="about_page_item_icon">
            <img src={about3} alt="about3" />
          </div>
          <div className="about_page_item_text">
            <b>10x Better Communication</b>
            <p>
              Extensive communication occurs regularly, so expectations are
              captured and a control plan is developed and deployed. Jira,
              Taiga, Trello, Slack, Skype, Zoom, Google Meet are tools we use to
              communicate dynamically and track performance.
            </p>
          </div>
        </div>
        <div className="about_page_item">
          <div className="about_page_item_icon">
            <img src={about4} alt="about4" />
          </div>
          <div className="about_page_item_text">
            <b>100+ Skills Available</b>
            <p>
              Among the technologies we cater to are .NET, Blazor, ASP.NET Core,
              ASP.NET MVC, ASP.NET Zero, SQL, SQL Server, MySQL, Oracle, React,
              Angular, React Native, Node JS, Python, Machine Learning, Data
              Engineers, Android and more.
            </p>
          </div>
        </div>
      </div>
      <div className="about_content_btn">
        <Link to="/hire" className="about_hire_btn">
          Hire a Developer
        </Link>
      </div>
    </div>
  );
};

export default About;
