import React from "react";
import "../css/dotNet.css";
import Navbar from "../components/navbar";
import MetaData from "../components/MetaData";
import WebHeader from "../components/webHeader.jsx";
import ClientsCompanyBrands from "../components/clientsCompanyBrands";
import WebDotNetDevelopersAvailable from "../components/webDotNetDevelopersAvailable.jsx";
import BudgetOffers from "../components/budgetOffers";
import HiringProcess from "../components/hiringProcess";
import SelectTheBest from "../components/selectTheBest";
import Portfolio from "../components/portfolio";

import Faqs from "../components/faqs";
import GetHelp from "../components/getHelp";
import Footer from "../components/footer";
import Technologies from "../components/technologies";
import Benefits from "../components/benefits";

const Web = () => {
  return (
    <div className="dotNet">
      <MetaData title="92 Logics - Graphic Designer" />
      <Navbar />
      <WebHeader />
      <div className="clientsCompany">
        <ClientsCompanyBrands />
      </div>
      <WebDotNetDevelopersAvailable />
      <BudgetOffers />
      <HiringProcess />
      <SelectTheBest />
      <Benefits />
      <Portfolio />

      <Faqs />
      <Technologies />
      <GetHelp />
      <Footer />
    </div>
  );
};

export default Web;
