import React from "react";
import "./App.css";
import "./css/mediaquery.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Navigation, Route } from "react-tiger-transition";
import Home from "./pages/home";
import AboutUs from "./pages/aboutUs";
import PortfolioPage from "./pages/portfolioPage";
import DotNet from "./pages/dotNet";
import Blazor from "./pages/blazor";
import Python from "./pages/python";
import ReactJs from "./pages/reactjs";
import Database from "./pages/database";
import Contact from "./pages/contact";
import Aspnet from "./pages/aspnet";
import Hire from "./pages/hire";
import SqlServer from "./pages/sqlServer";
import Mysql from "./pages/mysql";
import Oracle from "./pages/oracle";
import Developers from "./pages/developers";
import Designer from "./pages/designer";
import Uiux from "./pages/uiux";
import Graphic from "./pages/graphic";
import Web from "./pages/web";
import Aspnetmvc from "./pages/aspnetmvc";
import Digital from "./pages/digital";

const App = () => {
  return (
    <>
      <Router>
        <Navigation>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about-us">
            <AboutUs />
          </Route>
          <Route exact path="/contact-us">
            <Contact />
          </Route>
          <Route exact path="/hire">
            <Hire />
          </Route>
          <Route exact path="/portfolio">
            <PortfolioPage />
          </Route>
          <Route exact path="/developers/dot-net">
            <DotNet />
          </Route>
          <Route exact path="/developers/blazor">
            <Blazor />
          </Route>
          <Route exact path="/developers/python">
            <Python />
          </Route>
          <Route exact path="/developers/react">
            <ReactJs />
          </Route>
          <Route exact path="/developers/database">
            <Database />
          </Route>
          <Route exact path="/developers/asp-dot-net">
            <Aspnet />
          </Route>
          <Route exact path="/database/sql-server">
            <SqlServer />
          </Route>
          <Route exact path="/database/my-sql">
            <Mysql />
          </Route>
          <Route exact path="/database/oracle">
            <Oracle />
          </Route>
          <Route exact path="/developers">
            <Developers />
          </Route>
          <Route exact path="/designer">
            <Designer />
          </Route>
          <Route exact path="/designer/ui-ux">
            <Uiux />
          </Route>
          <Route exact path="/designer/graphic-designer">
            <Graphic />
          </Route>
          <Route exact path="/designer/web-designer">
            <Web />
          </Route>
          <Route exact path="/developers/asp-dot-net-mvc">
            <Aspnetmvc />
          </Route>
          <Route exact path="/developers/digital-marketing-manager">
            <Digital />
          </Route>
        </Navigation>
      </Router>
    </>
  );
};

export default App;
