import React from "react";
import Navbar from "../components/navbar";
import Banners from "../components/banners";
import ClientsCompanyBrands from "../components/clientsCompanyBrands";
import OurServices from "../components/ourServices";
import About from "../components/about";
import Portfolio from "../components/portfolio";
import Employees from "../components/employees";
import Process from "../components/process";
import Faqs from "../components/faqs";
import Technologies from "../components/technologies";
import GetHelp from "../components/getHelp";
import Footer from "../components/footer";
import Reviews from "../components/reviews";

const Home = () => {
  return (
    <>
      <Navbar />
      <Banners />
      <ClientsCompanyBrands />
      <OurServices />
      <About />
      <Portfolio />
      <Employees />
      <Process />
      <Reviews />
      <Faqs />
      <Technologies />
      <GetHelp />
      <Footer />
    </>
  );
};

export default Home;
