import React from "react";
import "../css/dotNet.css";
import Navbar from "../components/navbar";
import MetaData from "../components/MetaData";
import ReactHeader from "../components/reactHeader";
import ClientsCompanyBrands from "../components/clientsCompanyBrands";
import ReactDedicatedDotNet from "../components/reactDedicatedDotNet.jsx";
import ReactDotNetDevelopersAvailable from "../components/reactDotNetDevelopersAvailable";
import BudgetOffers from "../components/budgetOffers";
import HiringProcess from "../components/hiringProcess";
import SelectTheBest from "../components/selectTheBest";
import Portfolio from "../components/portfolio";

import Faqs from "../components/faqs";
import GetHelp from "../components/getHelp";
import Footer from "../components/footer";
import Technologies from "../components/technologies";
import Benefits from "../components/benefits";

const ReactJs = () => {
  return (
    <div className="dotNet">
      <MetaData title="92 Logics - React Developer" />
      <Navbar />
      <ReactHeader />
      <div className="clientsCompany">
        <ClientsCompanyBrands />
      </div>
      <ReactDedicatedDotNet />
      <ReactDotNetDevelopersAvailable />
      <BudgetOffers />
      <HiringProcess />
      <SelectTheBest />
      <Benefits />
      <Portfolio />

      <Faqs />
      <Technologies />
      <GetHelp />
      <Footer />
    </div>
  );
};

export default ReactJs;
