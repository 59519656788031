import React from "react";
import "../css/hire.css";
import Navbar from "../components/navbar";
import MetaData from "../components/MetaData";
import GetHelp from "../components/getHelp";
import Footer from "../components/footer";

const Hire = () => {
  return (
    <div className="hire">
      <MetaData title="92 Logics - Hire " />
      <Navbar />
      <div className="hire_content">
        <div className="hire_container">
          <div className="hire_title">
            Thank you for your interest in hiring through 92 Logics.
          </div>
          <div className="hire_sub_title">
            We would like to ask you a few questions to understand your business
            needs better. Let's get to the bottom of what you need...
          </div>

          <div className="hire_form">
            <div className="s_hire_form">
              <label htmlFor="">Name *</label>
              <input type="text" placeholder="Name" />
            </div>
            <div className="s_hire_form">
              <label htmlFor="">Phone Number *</label>
              <input type="number" placeholder="Phone Number" />
            </div>
            <div className="s_hire_form">
              <label htmlFor="">Email *</label>
              <input type="email" placeholder="Email" />
            </div>
            <div className="hire_btn">
              <button>Get Started</button>
            </div>
          </div>
        </div>
        <div className="right_hire_image">
          <img src="/assets/hire.png" alt="hire" width="400" />
        </div>
      </div>
      <GetHelp />
      <Footer />
    </div>
  );
};

export default Hire;
