import React from "react";
import "../css/getHelp.css";
import { Link } from "react-router-dom";

const GetHelp = () => {
  return (
    <div className="get_help">
      <div className="page_content">
        <div className="left_side_info">
          <h1>You Might Have Questions</h1>
          <p>We are here for you!</p>
        </div>
        <div className="right_side_button">
          <Link to="/contact-us" className="get_help_btn">
            Get Help
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GetHelp;
