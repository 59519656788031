import React from "react";
import "../css/dotNetHeader.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";

const WebHeader = () => {
  const employeeList = [
    {
      id: 1,
      empImage: "/assets/randomUser.png",
      empName: "Adeel Baig",
      empRole: "Web Designer",
    },
    {
      id: 2,
      empImage: "/assets/blazordev.jpg",
      empName: "Qaiser Mehmood",
      empRole: "Senior Web Designer",
    },
    {
      id: 3,
      empImage: "/assets/randomUser.png",
      empName: "Ahmad Ali",
      empRole: "Web Designer",
    },
  ];
  return (
    <div className="dotNetHeader">
      <div className="dotNetHeader-content">
        <h1 className="dotNetHeader_we">
          <span style={{ color: "#0F4C81" }}>Web Designers for Hire</span>
        </h1>
        <div className="dotNetHeader_create-section">
          <div className="dotNetHeader_content_para">
            Website designing calls for many nuisances as we have to look after
            all the best features like color theory, visual flow, proportions,
            typography, user psychology, layout, imagery, etc. But, we make it a
            breeze by giving you a head start by listing the fantastic web
            designers for hire.
          </div>
          <div className="dotNetHeader_content_btn">
            <Link to="/hire" className="dotnet_hire_btn">
              Hire a Developer
            </Link>
          </div>
        </div>
      </div>

      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination, Autoplay]}
        autoplay={true}
        className="dotNetHeader-image"
      >
        {employeeList.map((emp) => (
          <SwiperSlide key={emp.id}>
            <img src={emp.empImage} />
            <div className="person_name">{emp.empName}</div>
            <div className="person_role">{emp.empRole}</div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default WebHeader;
