import React, { useState } from "react";
import "../css/navbar.css";
import { AiOutlineMenu } from "react-icons/ai";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [panel, setPanel] = useState(false);
  const [spanel, ssetPanel] = useState(false);

  const openPanel = () => {
    setPanel(!panel);
    if (spanel) {
      ssetPanel(false);
    }
  };

  const sopenPanel = () => {
    ssetPanel(!spanel);
    if (panel) {
      setPanel(false);
    }
  };

  return (
    <>
      <div className="app__navbar">
        <Link to="/" className="app__navbar-logo">
          <img src="/assets/companyBrand.png" alt="brand" />
        </Link>
        <div className="menu__icon" onClick={() => setToggle(!toggle)}>
          <AiOutlineMenu />
        </div>
        <hr />
        <ul
          className={`app__navbar-links ${
            toggle ? "app__navbar-links active" : "app__navbar-links"
          }`}
        >
          <li className="app__flex p-text">
            <div />
            <Link to="/">Home</Link>
          </li>
          <li className="app__flex p-text" onClick={openPanel}>
            <div />
            <Link to="/developers">Developers</Link>
            <span className="dropdown_menu_icon">
              <RiArrowDropDownLine />
            </span>
          </li>
          <li className="app__flex p-text" onClick={sopenPanel}>
            <div />
            <Link to="/designer">Designer</Link>
            <span className="dropdown_menu_icon">
              <RiArrowDropDownLine />
            </span>
          </li>
          <li className="app__flex p-text">
            <div />
            <Link to="/about-us">About Us</Link>
          </li>
          <li className="app__flex p-text">
            <div />
            <Link to="/portfolio">Portfolio</Link>
          </li>
          <li className="app__flex p-text">
            <div />
            <Link to="/contact-us">Contact Us</Link>
          </li>
          <li className="app__flex p-text">
            <Link to="/hire" className="hire_btn">
              Hire a Developer
            </Link>
          </li>
        </ul>
      </div>
      <div className={panel ? "secondary_panel open" : "secondary_panel"}>
        <div className="secondary_menu_panel">
          <div className="left_side_message">
            <p>
              Delivering purpose-built software solutions that build lasting
              client relations.
            </p>
          </div>
          <div className="right_side_menu_details">
            <div className="left_side_web_development">
              <p>Web Development</p>
              <ul>
                <li>
                  <Link to="/developers/dot-net">.NET</Link>
                </li>
                <li>
                  <Link to="/developers/asp-dot-net-mvc">ASP.NET MVC</Link>
                </li>
                <li>
                  <Link to="/developers/asp-dot-net">ASP.NET Core</Link>
                </li>
                <li>
                  <Link to="/developers/blazor">Blazor</Link>
                </li>
                <li>
                  <Link to="/developers/react">React</Link>
                </li>
                <li>
                  <Link to="/developers/python">Python</Link>
                </li>
              </ul>
            </div>
            <div className="right_side_mobile_development">
              <p>Database Development</p>
              <ul>
                <li>
                  <Link to="/database/sql-server">SQL Server</Link>
                </li>
                <li>
                  <Link to="/database/my-sql">MySQL</Link>
                </li>
                <li>
                  <Link to="/database/oracle">Oracle</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* web designer side */}
      <div className={spanel ? "third_panel open" : "third_panel"}>
        <div className="third_menu_panel">
          <div className="left_side_message">
            <p>
              Delivering purpose-built software solutions that build lasting
              client relations.
            </p>
          </div>
          <div className="right_side_third_menu_details">
            <div className="left_side_designing_links">
              <ul>
                <li>
                  <Link to="/designer/ui-ux">UI&UX Designer</Link>
                </li>
                <li>
                  <Link to="/designer/graphic-designer">Graphic Designer</Link>
                </li>
                <li>
                  <Link to="/designer/web-designer">Web Designer</Link>
                </li>
                <li>
                  <Link to="/developers/digital-marketing-manager">
                    Digital Marketing Managers
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
