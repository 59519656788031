import React from "react";
import "../css/dotNet.css";
import Navbar from "../components/navbar";
import MetaData from "../components/MetaData";
import AspnetmvcHeader from "../components/aspnetmvcHeader";
import ClientsCompanyBrands from "../components/clientsCompanyBrands";
import AspnetmvcDedicatedDotNet from "../components/aspnetmvcDedicatedDotNet.jsx";
import AspnetmvcDotNetDevelopersAvailable from "../components/aspnetmvcDotNetDevelopersAvailable.jsx";
import BudgetOffers from "../components/budgetOffers";
import HiringProcess from "../components/hiringProcess";
import SelectTheBest from "../components/selectTheBest";
import Portfolio from "../components/portfolio";

import Faqs from "../components/faqs";
import GetHelp from "../components/getHelp";
import Footer from "../components/footer";
import Technologies from "../components/technologies";
import Benefits from "../components/benefits";

const Aspnetmvc = () => {
  return (
    <div className="dotNet">
      <MetaData title="92 Logics - ASP.NET MVC Developer" />
      <Navbar />
      <AspnetmvcHeader />
      <div className="clientsCompany">
        <ClientsCompanyBrands />
      </div>
      <AspnetmvcDedicatedDotNet />
      <AspnetmvcDotNetDevelopersAvailable />
      <BudgetOffers />
      <HiringProcess />
      <SelectTheBest />
      <Benefits />
      <Portfolio />

      <Faqs />
      <Technologies />
      <GetHelp />
      <Footer />
    </div>
  );
};

export default Aspnetmvc;
