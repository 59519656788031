import React from "react";
import "../css/dedicatedDotNet.css";

const AspnetDedicatedDotNet = () => {
  return (
    <div className="dedicatedDotNet">
      <div className="dedicatedDotNet-content">
        <h1 className="dedicatedDotNet_we">
          <span>Dedicated ASP.NET Core</span>
        </h1>
        <div className="dedicatedDotNet_create-section">
          <div className="dedicatedDotNet_awesome">Developers For Hire</div>
          <div className="dedicatedDotNet_content_para">
            Hire dedicated ASP.NET Core developers, project managers, scrum
            masters, UI designers, business analysts, and QA testers for all
            your needs under one roof. Hire ASP.NET Core developers, project
            managers, scrum masters, UI designers, business analysts, and QA
            testers under one roof without any restriction of the team size at
            affordable cost. Regardless of the stage of your project, our
            ASP.NET Core developers can start working immediately! It doesn’t
            matter if it is design, development, or testing you can count on us
            to take you to the finish line!
          </div>
          <div className="dedicatedDotNet_content_btn">
            <button>Hire a Developer</button>
          </div>
        </div>
      </div>
      <div className="dedicatedDotNet-image">
        <img src="/assets/LC.png" alt="lc" />
      </div>
    </div>
  );
};

export default AspnetDedicatedDotNet;
