import React from "react";
import "../css/clientsCompanyBrands.css";
import company1 from "./../svgs/company1.svg";
import company2 from "./../svgs/company2.png";
import company3 from "./../svgs/company3.jpg";
import company4 from "./../svgs/company4.webp";
import company5 from "./../svgs/company5.webp";
import company7 from "./../svgs/visual.svg";

const ClientsCompanyBrands = () => {
  return (
    <div className="clientsCompanyBrands">
      <div className="left_client">
        <div className="tab_title">Our Clients</div>
      </div>
      <hr className="single_border" />
      <div className="companies_logos">
        <div className="companies_logo_list">
          <div className="single_logo" style={{ width: "30px" }}>
            <img
              src={company3}
              alt="blueberry"
              width="50"
              title="BlueberryCMS"
            />
          </div>
          <div className="single_logo" style={{ width: "30px" }}>
            <img
              src="assets/stream.png"
              alt="stream"
              width="50"
              title="Sulotion Stream"
            />
          </div>
          <div className="single_logo" style={{ width: "30px" }}>
            <img
              src="/assets/moorewe.png"
              alt="moorewe"
              width="40"
              title="MooreWe"
            />
          </div>
          <div className="single_logo">
            <img
              src={company1}
              alt="knowledgeshop"
              width="150"
              title="Knowledge Shop"
            />
          </div>
          <div className="single_logo">
            <img
              src={company2}
              alt="merit wealth"
              width="150"
              title="Merit Wealth"
            />
          </div>
          <div className="single_logo">
            <img
              src={company4}
              alt="hayes night"
              width="150"
              title="Hayes Night"
            />
          </div>
          <div className="single_logo">
            <img
              src={company5}
              alt="creattivesims"
              width="150"
              title="CreativeSims"
            />
          </div>
          <div className="single_logo">
            <img
              src={company7}
              alt="visual"
              width="150"
              title="Visual Data Software Corporation"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsCompanyBrands;
