import React from "react";
import "../css/services.css";
import service1 from "./../svgs/service1.svg";
import service2 from "./../svgs/service2.svg";
import service3 from "./../svgs/service3.svg";
import service4 from "./../svgs/service4.svg";

const Service = ({ serviceImage, service_sub_title, service_desc }) => {
  return (
    <div className="service">
      <div className="service_icon">
        <img
          src={serviceImage}
          alt="service Image"
          width="60px"
          height="50px"
        />
      </div>
      <div className="service_sub_title">{service_sub_title}</div>
      <div className="service_desc">{service_desc}</div>
    </div>
  );
};

const OurServices = () => {
  return (
    <div className="services_page">
      <div className="left_service_ondemand_image">
        <img src="/assets/Software-Engineers-on-demand.webp" alt="ondemand" />
      </div>
      <div className="right_services">
        <p className="service_title">
          Software Engineers <b>On-demand</b>
        </p>
        <div className="services_container">
          <Service
            serviceImage={service1}
            service_sub_title="Hire Mobile Developers"
            service_desc="If you want to reach a wider audience, experience marketing opportunities, and target your audience immediately, hire expert mobile app developers."
          />
          <Service
            serviceImage={service2}
            service_sub_title="Hire Software Developers"
            service_desc="We offer top software engineers, developers and programmers on an hourly, part-time, or full-time contract basis."
          />
          <Service
            serviceImage={service3}
            service_sub_title="Hire UI/UX Engineers"
            service_desc="We have more than 10 UI/UX, and front-end engineers on board. For mission-critical software projects, our developers are trusted by top start-ups and SMBs."
          />
          <Service
            serviceImage={service4}
            service_sub_title="Hire E-commerce Developers"
            service_desc="The technicalities of building an E-commerce business require recruiting skilled E-commerce developers from a credible platform like ours."
          />
        </div>
      </div>
    </div>
  );
};

export default OurServices;
