import React from "react";
import "../css/chairmanStatment.css";

const ChairmanStatment = () => {
  return (
    <div className="chairmanStatment">
      <div className="left_chairman_image">
        <img src="/assets/ceo.png" alt="" width="350" />
      </div>
      <div className="right_side_chairman_info">
        <div className="chairman_title">CEO Statement</div>
        <div className="chairman_message">
          “In a world driven by digital innovation, we provide the web
          development talent to turn your vision into reality, no matter where
          you are. Together, let's create success, one code at a time.”
        </div>
        <div className="chairman_name">
          {" "}
          <span>CEO</span> - Qaiser Mehmood
        </div>
        <div className="chairman_linkedin">
          <a href="pk.linkedin.com/company/92logics" target="_blank">
            Linked in
          </a>
        </div>
      </div>
    </div>
  );
};

export default ChairmanStatment;
