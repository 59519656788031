import React from "react";
import "../css/behindMind.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";

const BehindMind = () => {
  const behindBrainData = [
    {
      id: 1,
      name: "Qaiser Mehmood",
      role: "Senior Software Engineer",
      image: "/assets/ceo.png",
    },
    {
      id: 2,
      name: "Usman Ashraf",
      role: "Senior Software Developer",
      image: "/assets/employee7.png",
    },
    {
      id: 3,
      name: "Adeel Baig",
      role: ".NET Developer",
      image: "/assets/adeelsahib2.png",
    },
  ];
  return (
    <div className="behindBrain">
      <div className="behindBrain_title">
        <div>Meet the</div>&nbsp;&nbsp;
        <b className="behindBrain_title_second">Brains Behind 92 Logics</b>
      </div>
      <div className="behindBrain_content">
        <Swiper
          spaceBetween={30}
          slidesPerView={4}
          breakpoints={{
            320: {
              width: 320,
              slidesPerView: 1,
            },
            768: {
              width: 768,
              slidesPerView: 2,
            },
          }}
        >
          {behindBrainData.map((behindBrain) => (
            <SwiperSlide key={behindBrain.id} className="swiperslider">
              <div className="behindBrain_single_emp">
                <div className="behindBrain_single_emp_img">
                  <img src={behindBrain.image} alt={behindBrain.name} />
                </div>
                <h2>{behindBrain.name}</h2>
                <p>{behindBrain.role}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default BehindMind;
