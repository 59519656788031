import React from "react";
import "../css/contact.css";
import Navbar from "../components/navbar";
import MetaData from "../components/MetaData";
import GetHelp from "../components/getHelp";
import Footer from "../components/footer";

const Contact = () => {
  return (
    <div className="contact">
      <MetaData title="92 Logics - Contact" />
      <Navbar />
      <div className="contact_content">
        <div className="contact_top">
          <h1>Hire the Right People!</h1>
          <p>
            Seeking a <b>dynamic development team</b> that can either be a part
            of your existing workforce or works as an independent extension?
          </p>
        </div>
        <div className="contact_form_panel">
          <div className="contact_form">
            <div className="s_input">
              <label htmlFor="">Full Name *</label>
              <input type="text" placeholder="Full Name" />
            </div>
            <div className="s_input">
              <label htmlFor="">Email *</label>
              <input type="email" placeholder="Email" />
            </div>
            <div className="s_input">
              <label htmlFor="">General Questions *</label>
              <select name="" id="">
                <option value="">Select</option>
                <option value="">Hiring</option>
                <option value="">Help & Support</option>
                <option value="">General Questions</option>
                <option value="">other</option>
              </select>
            </div>
            <div className="s_input">
              <label htmlFor="">How can we help you? *</label>
              <textarea
                type="email"
                placeholder="I would like to know about.."
              />
            </div>
            <div className="send_btn">
              <button>Send Message</button>
            </div>
          </div>
          <div className="right_some_info">
            <div className="getintouch_title">
              <h1>Get in Touch.</h1>
            </div>
            <p>
              Looking for more information about hiring{" "}
              <b>remote developers?</b>
            </p>
            <div className="s_info">
              <div className="s_info_title">
                <h1>WhatsApp Number</h1>
              </div>
              <a href="https://wa.me/+923306669292" target="_blank">
                +92 3306669292
              </a>
            </div>
            <div className="s_info">
              <div className="s_info_title">
                <h1>Business Email</h1>
              </div>
              <a href="mailto:office@92logics.info">office@92logics.info</a>
            </div>
            <div className="s_info">
              <div className="s_info_title">
                <h1>Mailling Address</h1>
              </div>
              <p>
                92 Logics 2nd Floor, Emirates Tower, M-13, F-7 Markaz,
                Islamabad, Pakistan.
              </p>
            </div>
          </div>
        </div>
      </div>
      <GetHelp />
      <Footer />
    </div>
  );
};

export default Contact;
