import React from "react";
import "../css/dotNetDevelopersAvailable.css";

const UiuxDotNetDevelopersAvailable = () => {
  const employeeData = [
    {
      id: 1,
      name: "Qaiser Mehmood",
      role: "UI/UX Manager",
      message:
        "With 18+ years of experience in designing digital experiences and leading design teams, Qaiser loves big ideas and ambitious projects.",
      image: "/assets/randomUser.png",
      skills: ["Figma", "Blender", "Adobe XD"],
    },
    {
      id: 2,
      name: "Ahmad Ali",
      role: "UI Engineer",
      message:
        "With foure years of experience as a senior UI Engineer, Ahmad has great knowledge of HTML, CSS, SaaS, Bootstrap5, ReactJS, & VueJS.",
      image: "/assets/randomUser.png",
      skills: ["React", "HTML5", "CSS3", "JQuery"],
    },
    {
      id: 3,
      name: "Adeel Baig",
      role: "UI/UX Designer",
      message:
        "Adeel specializes in creating 2D animation videos, animated posts, whiteboard animations, motion graphics, and info-graphics.",
      image: "/assets/randomUser.png",
      skills: ["Figma", "Blender", "Adobe XD"],
    },
  ];

  return (
    <div className="dotNetDevelopersAvailable">
      <div className="dotNetDevelopersAvailable_title">
        <div>
          Designers <b>Currently Available</b>
        </div>
        <p className="dotNetDevelopersAvailable_paragraph">
          The goal of UI/UX is to create digital experiences that are intuitive,
          engaging, and deliver measurable results. Hence our UI and UX
          designers are fiercely passionate about designing beautiful and
          responsive experiences, highly trained, and fully committed to
          providing designs that delight you and generate a significant return
          on investment.
        </p>
      </div>
      <div className="dotNetDevelopersAvailable_content">
        <div className="dotNetDevelopersAvailable_employees">
          {employeeData.map((employee) => (
            <div
              className="dotNetDevelopersAvailable_single_emp"
              key={employee.id}
            >
              <div className="dotNetDevelopersAvailable_single_emp_img">
                <img src={employee.image} alt={employee.name} />
              </div>
              <h2>{employee.name}</h2>
              <p>{employee.role}</p>
              <div className="span">{employee.message}</div>
              <div className="dotNetDevelopersAvailable_single_emp_skills_list">
                {employee.skills.map((skill, index) => (
                  <span key={index}>{skill}</span>
                ))}
              </div>
              <div className="dotNetDevelopersAvailable_employee_hire_btn">
                <a href="#">Hire Now</a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UiuxDotNetDevelopersAvailable;
