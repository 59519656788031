import React from "react";
import "../css/siringProcess.css";

const HiringProcess = () => {
  return (
    <div className="hiringProcess">
      <div className="hiringProcess_title">
        Process To <b>Hire 92 Logics</b>
      </div>
      <div className="top_hiring_image">
        <img src="/assets/hiringProcess.png" alt="hiringProcess" />
      </div>
      <div className="bottom_hiring_content">
        <div className="first_block">
          <h3 className="block_title">Choosing</h3>
          <p className="block_desc">
            Choose from an extensive range of resources available for all stacks
            and services.
          </p>
        </div>
        <div className="first_block">
          <h3 className="block_title">Interview</h3>
          <p className="block_desc">
            Interview your resources to get an idea of their skills and
            capabilities and see who suits you best.
          </p>
        </div>
        <div className="first_block">
          <h3 className="block_title">Hire</h3>
          <p className="block_desc">
            Once you have chosen your desired resources, now is the time to hire
            them in your team.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HiringProcess;
