import React from "react";
import "../css/dotNet.css";
import Navbar from "../components/navbar";
import MetaData from "../components/MetaData";
import AspnetHeader from "../components/aspnetHeader";
import ClientsCompanyBrands from "../components/clientsCompanyBrands";
import AspnetDedicatedDotNet from "../components/aspnetDedicatedDotNet";
import AspnetDotNetDevelopersAvailable from "../components/aspnetDotNetDevelopersAvailable";
import BudgetOffers from "../components/budgetOffers";
import HiringProcess from "../components/hiringProcess";
import SelectTheBest from "../components/selectTheBest";
import Portfolio from "../components/portfolio";

import Faqs from "../components/faqs";
import GetHelp from "../components/getHelp";
import Footer from "../components/footer";
import Technologies from "../components/technologies";
import Benefits from "../components/benefits";

const Aspnet = () => {
  return (
    <div className="dotNet">
      <MetaData title="92 Logics - ASP.NET Core Developer" />
      <Navbar />
      <AspnetHeader />
      <div className="clientsCompany">
        <ClientsCompanyBrands />
      </div>
      <AspnetDedicatedDotNet />
      <AspnetDotNetDevelopersAvailable />
      <BudgetOffers />
      <HiringProcess />
      <SelectTheBest />
      <Benefits />
      <Portfolio />

      <Faqs />
      <Technologies />
      <GetHelp />
      <Footer />
    </div>
  );
};

export default Aspnet;
