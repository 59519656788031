import React from "react";
import "../css/dotNet.css";
import Navbar from "../components/navbar";
import MetaData from "../components/MetaData";
import UiuxHeader from "../components/UiuxHeader";
import ClientsCompanyBrands from "../components/clientsCompanyBrands";
import UiuxDotNetDevelopersAvailable from "../components/uiuxDotNetDevelopersAvailable";
import BudgetOffers from "../components/budgetOffers";
import HiringProcess from "../components/hiringProcess";
import SelectTheBest from "../components/selectTheBest";
import Portfolio from "../components/portfolio";

import Faqs from "../components/faqs";
import GetHelp from "../components/getHelp";
import Footer from "../components/footer";
import Technologies from "../components/technologies";
import Benefits from "../components/benefits";

const Uiux = () => {
  return (
    <div className="dotNet">
      <MetaData title="92 Logics - UI & UX Developer" />
      <Navbar />
      <UiuxHeader />
      <div className="clientsCompany">
        <ClientsCompanyBrands />
      </div>
      <UiuxDotNetDevelopersAvailable />
      <BudgetOffers />
      <HiringProcess />
      <SelectTheBest />
      <Benefits />
      <Portfolio />

      <Faqs />
      <Technologies />
      <GetHelp />
      <Footer />
    </div>
  );
};

export default Uiux;
