import React from "react";
import "../css/reviews.css";

const Reviews = () => {
  return (
    <>
      <section className="reviews__section">
        <h2 className="p__head-text">Feedbacks</h2>
        <div className="reviews">
          <div className="single__review">
            <div className="client__info">
              <div className="client__name">
                <div>Paul Starkey</div> &nbsp; - &nbsp;{" "}
                <p> Blueberry Inc. - USA</p>
              </div>
              <div className="client__rating">⭐⭐⭐⭐⭐</div>
            </div>
            <div className="review__desc">
              The dev team at 92 Logics are good at what they do. A benefit to
              any company needing their help. Highly recommend them. Usman
              Ashraf is great to work with. He is always available when needed
              and products quality coding for our platform. Excellent
              Freelancer! Thank you!
            </div>
          </div>
          <div className="single__review">
            <div className="client__info">
              <div className="client__name">
                <div>Travis Cook</div> &nbsp; - &nbsp;{" "}
                <p>Solution Stream - USA</p>
              </div>
              <div className="client__rating">⭐⭐⭐⭐⭐</div>
            </div>
            <div className="review__desc">
              Qaiser was great to work with and very helpful and willing, solid
              .net and full stack developer.He did a great job and was good at
              communicating with us. He is also technically knowledgeable as a
              programmer and SQL query writer.
            </div>
          </div>

          <div className="single__review">
            <div className="client__info">
              <div className="client__name">
                <div>
                  Keith <span className="res_short">Mason</span>
                </div>{" "}
                &nbsp; - &nbsp; <p>Visual Data Software Corporation - USA</p>
              </div>
              <div className="client__rating">⭐⭐⭐⭐⭐</div>
            </div>
            <div className="review__desc">
              Qaiser is an extremely talented senior software engineer. His many
              years of experience allowed him to provide our team with a strong
              technical leadership that our off-site contractors needed. He
              frequently provided suggestions to improve code quality and
              offered alternative solutions to complex problems. Qaiser always
              has a great attitude and a strong work ethic.
            </div>
          </div>

          <div className="single__review">
            <div className="client__info">
              <div className="client__name">
                <div>
                  Willie <span className="res_short">Pritchett</span>
                </div>{" "}
                &nbsp; - &nbsp; <p>Mega Input Data Services, Inc. - USA</p>
              </div>
              <div className="client__rating">⭐⭐⭐⭐⭐</div>
            </div>
            <div className="review__desc">
              Qaiser is an exceptional resource and has been one of my most
              loyal contractors. I will definitely use him again. Exceptional
              DBA!!!!! Fantastic work and speedy service. Does what he says when
              he says! Outstanding fast service as always.
            </div>
          </div>
          <div className="single__review">
            <div className="client__info">
              <div className="client__name">
                <div>Julian Kievit</div> &nbsp; - &nbsp;{" "}
                <p>Winkle B.V. - Netherlands</p>
              </div>
              <div className="client__rating">⭐⭐⭐⭐⭐</div>
            </div>
            <div className="review__desc">
              Qaiser is an experienced developer, with a structured approach to
              his work. With clear specifications and tasks, he can work well
              independently and is an asset in a team of developers. Thank you
              for your contribution over the past months, Qaiser!
            </div>
          </div>
          <div className="single__review">
            <div className="client__info">
              <div className="client__name">
                <div>Mitch Labrador</div> &nbsp; - &nbsp;{" "}
                <p>CreativeSims - USA</p>
              </div>
              <div className="client__rating">⭐⭐⭐⭐⭐</div>
            </div>
            <div className="review__desc">
              Solid developer and will continue to work with him in future
              projects.
            </div>
          </div>
          <div className="single__review">
            <div className="client__info">
              <div className="client__name">
                <div>Mitch Labrador</div> &nbsp; - &nbsp;{" "}
                <p>CreativeSims - USA</p>
              </div>
              <div className="client__rating">⭐⭐⭐⭐⭐</div>
            </div>
            <div className="review__desc">
              Solid developer and will continue to work with him in future
              projects.
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Reviews;
