import React from "react";
import "../css/dotNetDevelopersAvailable.css";

const GraphicDotNetDevelopersAvailable = () => {
  const employeeData = [
    {
      id: 1,
      name: "Ahmad Ali",
      role: "UI Engineer",
      message:
        "With foure years of experience as a senior UI Engineer, Ahmad has great knowledge of HTML, CSS, SaaS, Bootstrap5, ReactJS, & VueJS.",
      image: "/assets/randomUser.png",
      skills: ["React", "HTML5", "CSS3", "JQuery"],
    },
    {
      id: 2,
      name: "Qaiser Mehmood",
      role: "UI/UX Manager",
      message:
        "With 18+ years of experience in designing digital experiences and leading design teams, Qaiser loves big ideas and ambitious projects.",
      image: "/assets/randomUser.png",
      skills: ["Figma", "Blender", "Adobe XD"],
    },
    {
      id: 3,
      name: "Adeel Baig",
      role: "UI/UX Designer",
      message:
        "Adeel specializes in creating 2D animation videos, animated posts, whiteboard animations, motion graphics, and info-graphics.",
      image: "/assets/randomUser.png",
      skills: ["Figma", "Blender", "Adobe XD"],
    },
  ];

  return (
    <div className="dotNetDevelopersAvailable">
      <div className="dotNetDevelopersAvailable_title">
        <div>
          Graphic Designers <b>Currently Available</b>
        </div>
        <p className="dotNetDevelopersAvailable_paragraph">
          If you are planning to hire a graphic designer in terms of marketing,
          then it’s your best choice to deal with. We acquire market-competitive
          resources who deliver qualitative products on time with complete
          training for certain jobs. Your business will likely suffer more as a
          result of such an approach.
        </p>
      </div>
      <div className="dotNetDevelopersAvailable_content">
        <div className="dotNetDevelopersAvailable_employees">
          {employeeData.map((employee) => (
            <div
              className="dotNetDevelopersAvailable_single_emp"
              key={employee.id}
            >
              <div className="dotNetDevelopersAvailable_single_emp_img">
                <img src={employee.image} alt={employee.name} />
              </div>
              <h2>{employee.name}</h2>
              <p>{employee.role}</p>
              <div className="span">{employee.message}</div>
              <div className="dotNetDevelopersAvailable_single_emp_skills_list">
                {employee.skills.map((skill, index) => (
                  <span key={index}>{skill}</span>
                ))}
              </div>
              <div className="dotNetDevelopersAvailable_employee_hire_btn">
                <a href="#">Hire Now</a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GraphicDotNetDevelopersAvailable;
