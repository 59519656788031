import React, { useState } from "react";
import "../css/aboutHeader.css";
import ClientsCompanyBrands from "./clientsCompanyBrands";
import { Link } from "react-router-dom";

const AboutHeader = () => {
  const [ceo, setCeo] = useState({
    ceoImage: "/assets/ceo.png",
    ceoName: "Qaiser Mehmood",
    ceoRole: "Founder & CEO",
  });
  const [manager, setManager] = useState({
    ceoImage: "/assets/employee7.png",
    ceoName: "Anser Mehmood",
    ceoRole: "Project Manager",
  });

  const [adeelsahib, setAdeelsahib] = useState({
    ceoImage: "/assets/adeelsahib2.png",
    ceoName: "Adeel Baig",
    ceoRole: "Senior Developer",
  });

  const ceoImageHandle = () => {
    setCeo({
      ceoImage: "/assets/ceo.png",
      ceoName: "Qaiser Mehmood",
      ceoRole: "Founder & CEO",
    });
  };
  const managerImageHandle = () => {
    setCeo({
      ceoImage: "/assets/randomUser.png",
      ceoName: "Usman Ashraf",
      ceoRole: "Project Manager",
    });
  };
  const adeelSahibImageHandle = () => {
    setCeo({
      ceoImage: "/assets/adeelsahib2.png",
      ceoName: "Adeel Baig",
      ceoRole: "Senior Developer",
    });
  };

  return (
    <>
      <div className="aboutHeader">
        <div className="aboutHeader-content">
          <h1 className="aboutHeader_we">
            <span style={{ color: "#0F4C81" }}>Defy Boundaries!</span>
          </h1>
          <div className="aboutHeader_create-section">
            <div className="aboutHeader_awesome">Hire Top Talent</div>
            <div className="aboutHeader_content_para">
              If smaller tasks pile up, find extra manpower to help keep
              fixed-price projects organized and moving forward.
            </div>
            <div
              className="aboutHeader_content_btn"
              style={{ marginTop: "20px" }}
            >
              <Link to="/hire" className="dotnet_hire_btn">
                Hire a Developer
              </Link>
            </div>
          </div>
        </div>
        <div className="aboutHeader-image">
          <div className="content_changing_area">
            <div className="left_side_image">
              <img src={ceo.ceoImage} alt="ceo" style={{ width: "400px" }} />
            </div>
            <div className="right_person_info">
              <h4>{ceo.ceoName}</h4>
              <p>{ceo.ceoRole}</p>
            </div>
          </div>
          <div className="tab_action_area">
            <div className="tab_contant">
              <div className="tab_title">Our Experts</div>
              <div className="tab_images">
                <div className="tab_image" onClick={ceoImageHandle}>
                  <img src="/assets/ceo.png" alt="blazordev" />
                  <div className="tab_bottom_line"></div>
                </div>
                <div className="tab_image" onClick={managerImageHandle}>
                  <img src="/assets/employee7.png" alt="employee7" />
                  <div className="tab_bottom_line"></div>
                </div>
                <div className="tab_image" onClick={adeelSahibImageHandle}>
                  <img src="/assets/adeelsahib2.png" alt="adeelsahib" />
                  <div className="tab_bottom_line"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ClientsCompanyBrands />
    </>
  );
};

export default AboutHeader;
