import React from "react";
import "../css/banners.css";
import { Link } from "react-router-dom";

const Banners = () => {
  return (
    <>
      <div className="banner-boxe">
        <div className="banner-content">
          <h1 className="we">
            <span style={{ color: "#0F4C81" }}>Come</span>&nbsp;Build
          </h1>
          <div className="create-section">
            <div className="awesome">With Us!</div>
            <div className="banner_content_para">
              With more than{" "}
              <span style={{ color: "#0F4C81" }}>20+ engineers</span> on staff,
              we are a software development outsourcing company that has been
              unlocking digital opportunities for clients&nbsp;
              <span style={{ color: "#0F4C81" }}>since 2016.</span>
            </div>
            <div className="banner_content_btn">
              <Link to="/hire" className="banner_hire_btn">
                Hire a Developer
              </Link>
            </div>
          </div>
          <div className="twenty-four-services_bagde">
            <img src="/assets/24-7-services.png" alt="24-7-services" />
          </div>
          <div className="mony_back_gaurentee_bagde">
            <img src="/assets/guaratee.png" alt="money guaratee" />
          </div>
        </div>
        <div className="banner_images">
          <div className="first_image_block">
            <img src="/assets/blazordev.jpg" alt="blazordev" />
            <img src="/assets/busman.jpg" alt="busman" />
            <img src="/assets/adeelsahib.jpg" alt="adeelsahib" />
            <img src="/assets/ansirsahib.jpg" alt="ansirsahib" />
            <img src="/assets/mohsin.jpg" alt="mohsin" />
            <img src="/assets/hamza.jpg" alt="hamza" />
            <img src="/assets/asad.jpg" alt="asad" />
            <img src="/assets/ahmadali.jpg" alt="ahmadali" />
            <img src="/assets/randomUser.png" alt="randomUser" />
          </div>
        </div>
      </div>
    </>
  );
};
export default Banners;
