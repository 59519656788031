import React from "react";
import Portfolio from "../components/portfolio";
import Navbar from "../components/navbar";
import MetaData from "../components/MetaData";
import GetHelp from "../components/getHelp";
import Footer from "../components/footer";

const PortfolioPage = () => {
  return (
    <div className="PortfolioPage" style={{ marginTop: "0px" }}>
      <MetaData title="92 Logics - Portfolio" />
      <Navbar />
      <div style={{ marginTop: "30px" }}>
        <Portfolio />
      </div>
      <GetHelp />
      <Footer />
    </div>
  );
};

export default PortfolioPage;
