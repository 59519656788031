import React from "react";
import "../css/selectTheBest.css";
import service1 from "./../svgs/service1.svg";

const SelectBest = ({ serviceImage, service_sub_title, service_desc }) => {
  return (
    <div className="service">
      <div className="service_icon">
        <img
          src={serviceImage}
          alt="service Image"
          width="60px"
          height="50px"
        />
      </div>
      <div className="service_sub_title">{service_sub_title}</div>
      <div className="service_desc">{service_desc}</div>
    </div>
  );
};

const SelectTheBest = () => {
  return (
    <div className="services_page">
      <div className="left_service_ondemand_image">
        <img src="/assets/Software-Engineers-on-demand.webp" alt="ondemand" />
      </div>
      <div className="right_services">
        <p className="service_title">
          How We Select <b>The Best</b>
        </p>
        <div className="services_container">
          <SelectBest
            serviceImage="/assets/select1.png"
            service_sub_title="It’s All About Expertise"
            service_desc="It is important to review the experience and complexity of the products built by the developers in the past."
          />
          <SelectBest
            serviceImage="/assets/select2.png"
            service_sub_title="Enthusiasm & Communication"
            service_desc="Communication skills are tested over the phone. This allows us to better understand the candidate’s technical experience and motivation to work"
          />
          <SelectBest
            serviceImage={service1}
            service_sub_title="Technical Expertise"
            service_desc="During one or more face-to-face interviews, the developer’s involvement and performance are assessed by doing so, the platform is..."
          />
          <SelectBest
            serviceImage="/assets/select3.png"
            service_sub_title="Problem-solving Skills"
            service_desc="A skill-specific test measures designer's mental agility and problem-solving abilities. In addition, live evaluations, and timed..."
          />
        </div>
      </div>
    </div>
  );
};

export default SelectTheBest;
