import React from "react";
import "../css/benefits.css";

const Benefits = () => {
  return (
    <div className="benefits">
      <div className="benefits_title">
        You Will Benefit From <b>Working With Us</b>
      </div>
      <div className="benefits_content">
        <ul>
          <li>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                viewBox="0 0 92.75 71.52"
                width="30"
              >
                <defs></defs>
                <path
                  className="cls-1"
                  d="M16.33,27.17c-3.48-3.84-9.41-4.12-13.25-.64-3.84,3.48-4.12,9.41-.64,13.25l26.01,28.66c3.72,4.1,10.16,4.1,13.89,0L90.31,15.69c3.48-3.83,3.2-9.77-.63-13.25-3.83-3.48-9.77-3.2-13.25,.63L35.4,48.18,16.33,27.17Z"
                ></path>
              </svg>
            </span>
            Certified Developers
          </li>
          <li>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                viewBox="0 0 92.75 71.52"
                width="30"
              >
                <defs></defs>
                <path
                  className="cls-1"
                  d="M16.33,27.17c-3.48-3.84-9.41-4.12-13.25-.64-3.84,3.48-4.12,9.41-.64,13.25l26.01,28.66c3.72,4.1,10.16,4.1,13.89,0L90.31,15.69c3.48-3.83,3.2-9.77-.63-13.25-3.83-3.48-9.77-3.2-13.25,.63L35.4,48.18,16.33,27.17Z"
                ></path>
              </svg>
            </span>
            Periodic Reporting
          </li>
          <li>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                viewBox="0 0 92.75 71.52"
                width="30"
              >
                <defs></defs>
                <path
                  className="cls-1"
                  d="M16.33,27.17c-3.48-3.84-9.41-4.12-13.25-.64-3.84,3.48-4.12,9.41-.64,13.25l26.01,28.66c3.72,4.1,10.16,4.1,13.89,0L90.31,15.69c3.48-3.83,3.2-9.77-.63-13.25-3.83-3.48-9.77-3.2-13.25,.63L35.4,48.18,16.33,27.17Z"
                ></path>
              </svg>
            </span>
            No Time Zone Limitations
          </li>
          <li>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                viewBox="0 0 92.75 71.52"
                width="30"
              >
                <defs></defs>
                <path
                  className="cls-1"
                  d="M16.33,27.17c-3.48-3.84-9.41-4.12-13.25-.64-3.84,3.48-4.12,9.41-.64,13.25l26.01,28.66c3.72,4.1,10.16,4.1,13.89,0L90.31,15.69c3.48-3.83,3.2-9.77-.63-13.25-3.83-3.48-9.77-3.2-13.25,.63L35.4,48.18,16.33,27.17Z"
                ></path>
              </svg>
            </span>
            Deadline Adherence
          </li>
          <li>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                viewBox="0 0 92.75 71.52"
                width="30"
              >
                <defs></defs>
                <path
                  className="cls-1"
                  d="M16.33,27.17c-3.48-3.84-9.41-4.12-13.25-.64-3.84,3.48-4.12,9.41-.64,13.25l26.01,28.66c3.72,4.1,10.16,4.1,13.89,0L90.31,15.69c3.48-3.83,3.2-9.77-.63-13.25-3.83-3.48-9.77-3.2-13.25,.63L35.4,48.18,16.33,27.17Z"
                ></path>
              </svg>
            </span>
            No Hidden Costs
          </li>
          <li>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                viewBox="0 0 92.75 71.52"
                width="30"
              >
                <defs></defs>
                <path
                  className="cls-1"
                  d="M16.33,27.17c-3.48-3.84-9.41-4.12-13.25-.64-3.84,3.48-4.12,9.41-.64,13.25l26.01,28.66c3.72,4.1,10.16,4.1,13.89,0L90.31,15.69c3.48-3.83,3.2-9.77-.63-13.25-3.83-3.48-9.77-3.2-13.25,.63L35.4,48.18,16.33,27.17Z"
                ></path>
              </svg>
            </span>
            Fully Dedicated Resources
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Benefits;
