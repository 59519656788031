import React from "react";
import "../css/dotNetHeader.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";

const OracleHeader = () => {
  const employeeList = [
    {
      id: 1,
      empImage: "/assets/blazordev.jpg",
      empName: "Qaiser Mehmood",
      empRole: "Senior Oracle Developer",
    },
    {
      id: 2,
      empImage: "/assets/busman.jpg",
      empName: "Usman Ashraf",
      empRole: "Senior Oracle Developer",
    },
  ];
  return (
    <div className="dotNetHeader">
      <div className="dotNetHeader-content">
        <h1 className="dotNetHeader_we">
          <span style={{ color: "#0F4C81" }}>Oracle Developers For Hire</span>
        </h1>
        <div className="dotNetHeader_create-section">
          <div className="dotNetHeader_content_para">
            At 92 Logics we offer dedicated Oracle developers and engineers
            single and in teams on project and monthly basis at competitive and
            reasonable prices. Get, set, hire!
          </div>
          <div className="dotNetHeader_content_btn">
            <Link to="/hire" className="dotnet_hire_btn">
              Hire a Developer
            </Link>
          </div>
        </div>
      </div>

      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination, Autoplay]}
        autoplay={true}
        className="dotNetHeader-image"
      >
        {employeeList.map((emp) => (
          <SwiperSlide key={emp.id}>
            <img src={emp.empImage} />
            <div className="person_name">{emp.empName}</div>
            <div className="person_role">{emp.empRole}</div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default OracleHeader;
