import React from "react";
import "../css/dotNetDevelopersAvailable.css";
import { Link } from "react-router-dom";

const DotNetDevelopersAvailable = () => {
  const employeeData = [
    {
      id: 1,
      name: "Usman Ashraf",
      role: ".NET Developer",
      message:
        "Usman Asharaf can be your go-to person for all .Net and Angular related needs including designing, coding and more.",
      image: "/assets/randomUser.png",
      skills: [".NET", "ASP.NET MVC", "Jquery", "Angular"],
    },
    {
      id: 2,
      name: "Ayaz Mehmood",
      role: ".NET Developer",
      message:
        "Ayaz can be your go-to person for all .Net Core and JavaScript related needs including prototyping, coding and more.",
      image: "/assets/randomUser.png",
      skills: [".NET Core", "ASP.NET MVC", "JavaScript"],
    },

    {
      id: 3,
      name: "Ahmad Ali",
      role: ".NET Developer",
      message:
        "Ahmad be your go-to person for all .Net, React and Blazor  related needs including prototyping, coding and more.",
      image: "/assets/randomUser.png",
      skills: [".NET", "ASP.NET MVC", "React", "nodejs"],
    },
    {
      id: 4,
      name: "Anser Mahmood",
      role: ".NET Developer",
      message:
        "Anser Mahmood be your go-to person for all .Net and Scrum Master related needs including prototyping, coding and more.",
      image: "/assets/randomUser.png",
      skills: [".NET", "ASP.NET MVC", "Javascript", "SQl"],
    },
    {
      id: 5,
      name: "Adeel Baig",
      role: ".NET Developer",
      message:
        "Adeel Baig be your go-to person for all .Net, SQL and Blazor related needs including prototyping, coding and more.",
      image: "/assets/randomUser.png",
      skills: [".NET", "ASP.NET MVC", "Jquery", "SQL"],
    },
  ];

  return (
    <div className="dotNetDevelopersAvailable">
      <div className="dotNetDevelopersAvailable_title">
        <div>
          .Net Developers <b>Currently Available</b>
        </div>
        <p className="dotNetDevelopersAvailable_paragraph">
          At 92 Logics you will find top talented .NET developers, experts and
          consultants instantly within 48 hours with us. Steer your projects
          into the right direction with our full-stack .NET developers who are
          available on hourly, full-time and part time basis.
        </p>
      </div>
      <div className="dotNetDevelopersAvailable_content">
        <div className="dotNetDevelopersAvailable_employees">
          {employeeData.map((employee) => (
            <div
              className="dotNetDevelopersAvailable_single_emp"
              key={employee.id}
            >
              <div className="dotNetDevelopersAvailable_single_emp_img">
                <img src={employee.image} alt={employee.name} />
              </div>
              <h2>{employee.name}</h2>
              <p>{employee.role}</p>
              <div className="span">{employee.message}</div>
              <div className="dotNetDevelopersAvailable_single_emp_skills_list">
                {employee.skills.map((skill, index) => (
                  <span key={index}>{skill}</span>
                ))}
              </div>
              <div className="dotNetDevelopersAvailable_employee_hire_btn">
                <Link to="/hire">Hire Now</Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DotNetDevelopersAvailable;
