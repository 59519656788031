import React from "react";
import "../css/dotNetHeader.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";

const DigitalHeader = () => {
  const employeeList = [
    {
      id: 1,
      empImage: "/assets/blazordev.jpg",
      empName: "Qaiser Mehmood",
      empRole: "Senior Digital Marketing Managers",
    },
    {
      id: 2,
      empImage: "/assets/randomUser.png",
      empName: "Haider Raza",
      empRole: "Digital Marketing Managers",
    },
    {
      id: 3,
      empImage: "/assets/mohsin.jpg",
      empName: "Muhammad Mohsin",
      empRole: "Senior Digital Marketing Managers",
    },
    {
      id: 4,
      empImage: "/assets/hamza.jpg",
      empName: "Hamza Fakhar",
      empRole: "Senior Digital Marketing Managers",
    },
  ];
  return (
    <div className="dotNetHeader">
      <div className="dotNetHeader-content">
        <h1 className="dotNetHeader_we">
          <span style={{ color: "#0F4C81", fontSize: "55px" }}>
            Hire Digital Marketing Managers
          </span>
        </h1>
        <div className="dotNetHeader_create-section">
          <div className="dotNetHeader_content_para">
            The best way to complete high-quality projects on time is to hire a
            professional Digital Marketing Managers. Save your valuable time and
            money from correcting the mistakes that can be avoided easily with
            the help of an expert.
          </div>
          <div className="dotNetHeader_content_btn">
            <Link to="/hire" className="dotnet_hire_btn">
              Hire a Digital Marketing Managers
            </Link>
          </div>
        </div>
      </div>

      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination, Autoplay]}
        autoplay={true}
        className="dotNetHeader-image"
      >
        {employeeList.map((emp) => (
          <SwiperSlide key={emp.id}>
            <img src={emp.empImage} />
            <div className="person_name">{emp.empName}</div>
            <div className="person_role">{emp.empRole}</div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default DigitalHeader;
