import React from "react";
import "../css/portfolio.css";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

function PortfolioItem({ portImage, portTitle, portDesc }) {
  return (
    <div className="portfolio_item">
      <div className="portfolio_image">
        <img src={portImage} alt="" />
      </div>
      <div className="portfolio_name">{portTitle}</div>
      <div className="portfolio_desc">{portDesc}</div>
      <div className="go_hire_btn">
        <Link to="/hire" className="go_hire">
          <AiOutlineArrowRight />
        </Link>
      </div>
    </div>
  );
}

const Portfolio = () => {
  return (
    <div className="portfolio_page">
      <h2 className="portfolio_title">Our Creative Portfolio</h2>

      <div className="portfolio_items">
        <PortfolioItem
          portImage="/assets/portfolio4.png"
          portTitle="Blueberry CMS Inc. - USA"
        />
        <PortfolioItem
          portImage="/assets/portfolio1.jpg"
          portTitle="MERIT WEALTH - AUSTRALIA"
        />
        <PortfolioItem
          portImage="/assets/portfolio2.jpg"
          portTitle="KNOWLEDGE SHOP - AUSTRALIA"
        />
        <PortfolioItem
          portImage="/assets/portfolio3.jpg"
          portTitle="WALMART - USA"
        />
        <PortfolioItem
          portImage="/assets/portfolio5.png"
          portTitle="CreativeSims - USA"
        />
        <PortfolioItem
          portImage="/assets/portfolio6.png"
          portTitle="Hayes Knight - Australia"
        />
      </div>
    </div>
  );
};

export default Portfolio;
