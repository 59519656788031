import React from "react";
import "../css/employees.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";

import "swiper/css";

const Employees = () => {
  const employeeData = [
    {
      id: 1,
      name: "Qaiser Mehmood",
      role: "Senior Software Engineer",
      image: "/assets/ceo.png",
      skills: [
        ".NET",
        "Blazor",
        "ASP.NET MVC",
        "ASP.NET Zero",
        "SQL",
        "SQL Server",
      ],
    },

    {
      id: 2,
      name: "Usman Asharaf",
      role: "Senior Software Developer",
      image: "/assets/randomUser.png",
      skills: [
        ".NET",
        "ASP.NET MVC",
        "ASP.NET Zero",
        "SQL",
        "Database",
        "SQL Server",
      ],
    },
    {
      id: 3,
      name: "Anser Mahmood",
      role: ".NET Developer",
      image: "/assets/randomUser.png",
      skills: [".NET", "ASP.NET MVC", "SQL", "SQL Server", "Database"],
    },

    {
      id: 4,
      name: "Adeel Baig",
      role: ".NET SQL Developer",
      image: "/assets/adeelsahib2.png",
      skills: [
        ".NET",
        "ASP.NET MVC",
        "ASP.NET Core",
        "SQL",
        "SQL Server",
        "Database",
      ],
    },
    {
      id: 5,
      name: "Ahmad Ali",
      role: "React .NET Core Developer",
      image: "/assets/randomUser.png",
      skills: [".NET", "Blazor", "React", "ASP.NET Core", "ASP.NET MVC", "SQL"],
    },
    {
      id: 6,
      name: "Muhammad Asad",
      role: "Python Developer",
      image: "/assets/randomUser.png",
      skills: [
        "Python",
        "Web Scraping",
        "Web Automation",
        "Selenium",
        "Pandas",
      ],
    },
    {
      id: 7,
      name: "Zohaib Kashaf",
      role: "Python Developer",
      image: "/assets/randomUser.png",
      skills: ["Python", "Django", "Flask", "Web Automation"],
    },
    {
      id: 8,
      name: "Muhammad Tayyab",
      role: "Python Developer",
      image: "/assets/bhaitayyab2.png",
      skills: ["Python", "Django", "Flask", "Web Automation"],
    },
    {
      id: 9,
      name: "Haider Raza",
      role: "Digital Marketing Manager",
      image: "/assets/randomUser.png",
      skills: [
        "SEO",
        "Social Media Marketing",
        "Email Marketing",
        "Content Marketing",
      ],
    },
    {
      id: 10,
      name: "Muhammad Mohsin",
      role: "Digital Marketing Manager",
      image: "/assets/mohsin2.png",
      skills: [
        "Email Marketing",
        "Content Marketing",
        "Social Media Marketing",
        "SEO",
      ],
    },
    {
      id: 11,
      name: "Hamza Dar",
      role: "Digital Marketing Manager",
      image: "/assets/hamza2.png",
      skills: [
        "Email Marketing",
        "Content Marketing",
        "Social Media Marketing",
        "SEO",
      ],
    },
  ];

  return (
    <div className="employees">
      <div className="employee_title">
        <div>Get Access to 20+ Carefully Vetted</div>
        <b className="employee_title_second">Software Engineers</b>
      </div>
      <div className="employee_content">
        <Swiper
          spaceBetween={30}
          slidesPerView={4}
          modules={[Autoplay]}
          autoplay={true}
          breakpoints={{
            320: {
              width: 320,
              slidesPerView: 1,
            },
            768: {
              width: 768,
              slidesPerView: 2,
            },
          }}
        >
          {employeeData.map((employee) => (
            <SwiperSlide key={employee.id} className="swiperslider">
              <div className="single_emp">
                <div className="single_emp_img">
                  <img
                    src={employee.image}
                    alt={employee.name}
                    style={{ width: "200px", marginLeft: "50px" }}
                  />
                </div>
                <h2>{employee.name}</h2>
                <p>{employee.role}</p>
                <div className="single_emp_skills_list">
                  {employee.skills.map((skill, index) => (
                    <span key={index}>{skill}</span>
                  ))}
                </div>
                <div className="employee_hire_btn">
                  <Link to="/hire">Hire Now</Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Employees;
