import React from "react";
import "../css/itSolution.css";

const ItSolution = () => {
  return (
    <div className="itSolution">
      <div className="itSolution_title">
        Delivering IT Solutions <b>Since 2016</b>
      </div>
      <div className="circle_list">
        <div className="circle">
          <div className="circle_data">
            <div className="circle_percent">70+</div>
            <div className="circle_text">Projects Completed</div>
          </div>
        </div>
        <div className="circle">
          <div className="circle_data">
            <div className="circle_percent">20+</div>
            <div className="circle_text">Team Members</div>
          </div>
        </div>
        <div className="circle">
          <div className="circle_data">
            <div className="circle_percent">98+</div>
            <div className="circle_text">Positive Reviews</div>
          </div>
        </div>
        <div className="circle">
          <div className="circle_data">
            <div className="circle_percent">97+</div>
            <div className="circle_text">Happy Clients</div>
          </div>
        </div>
        <div className="circle">
          <div className="circle_data">
            <div className="circle_percent">100+</div>
            <div className="circle_text">Customer Satisfaction</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItSolution;
