import React from "react";
import "../css/process.css";
import process1 from "./../svgs/process1.svg";
import process2 from "./../svgs/process2.svg";
import process3 from "./../svgs/process3.svg";
import process4 from "./../svgs/process4.svg";

const Process = () => {
  return (
    <div className="process">
      <div className="process_title">
        Our <b>Process</b>
      </div>
      <div className="process_desc">
        Welcome to 92 Logics, a team of experienced web developers, engineers,
        consultants and designers based in Islamabad and Sialkot, Pakistan. Our
        goal is to provide the best remote team, whether you need full-time,
        part-time or contract basis.
      </div>
      <div className="process_content">
        <div className="left_process_image">
          <img src="/assets/process.jpg" alt="process" />
        </div>
        <div className="right_process_list">
          <div className="single_process">
            <div className="process_icon">
              <img src={process1} alt="process1" />
            </div>
            <div className="process_right_content">
              <b>Welcome Email </b> – Once you signup for our form, you receive
              a welcome email with a link to Zoom Meeting.
            </div>
          </div>
          <div className="single_process">
            <div className="process_icon">
              <img src={process2} alt="process1" />
            </div>
            <div className="process_right_content">
              <b>Discovery Call </b> – This call is intended to gather
              requirements, collect data and attributes to calculate project
              scope and cost.
            </div>
          </div>
          <div className="single_process">
            <div className="process_icon">
              <img src={process3} alt="process1" />
            </div>
            <div className="process_right_content">
              <b>Proposal </b> –Following the discovery call, you receive a
              formal proposal with the Statement of Work (SOW).
            </div>
          </div>
          <div className="single_process">
            <div className="process_icon">
              <img src={process4} alt="process1" />
            </div>
            <div className="process_right_content">
              <b>Kick-Off </b> – After you agree to the Statement of Work, our
              developers will be ready to start working right away.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
