import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";
import skype from "./../svgs/skype.svg";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="footer_items">
          <div className="first_company_logo_and_desc">
            <div className="company_logo">
              <Link to="/">
                <img
                  src="/assets/footerbrand.png"
                  alt="brand"
                  className="footer_brand"
                />
              </Link>
            </div>
            <div className="company_desc">
              Welcome to 92 Logics, a team of experienced web developers,
              engineers, consultants and designers based in Islamabad and
              Sialkot, Pakistan.
            </div>
          </div>
          <div className="about_links">
            <div className="about_links_title">About Us</div>
            <ul>
              <li>
                <Link to="/developers/dot-net">Hire a Developer</Link>
              </li>
              <li>
                <Link to="/designer/web-designer">Hire a Designer</Link>
              </li>
              <li>
                <Link to="/portfolio">Portfolio</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="most_indeman_skills">
            <div className="most_indeman_title">Most In-Demand Talent</div>
            <ul>
              <li>
                <Link to="/developers/dot-net">Hire .NET Developer</Link>
              </li>
              <li>
                <Link to="/developers/blazor">Hire Blazor Developer</Link>
              </li>
              <li>
                <Link to="/developers/react">Hire React Developer</Link>
              </li>
              <li>
                <Link to="/developers/python">Hire Python Developer</Link>
              </li>
              <li>
                <Link to="/developers/database">Hire SQL Developer</Link>
              </li>
            </ul>
          </div>
          <div className="contact_us_list">
            <div className="contact_us_title">Contact Us</div>
            <ul>
              <li>
                92 LOGICS 2nd Floor, Emirates Tower, M-13, F-7 Markaz,
                Islamabad, Pakistan.
              </li>
              <li>
                <a href="mailto:office@92logics.info">office@92logics.info</a>
              </li>
              <li>
                <a href="tel:(051) 2099102">(051) 2099102</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <footer className="second_footer">
        <div className="footer_compyRight">
          Copyright © 2023 - 92 Logics. All Rights Reserved.
        </div>

        <div className="social__icon">
          <div className="skype f__icon">
            <a href="skype:qaiser.mehmood?userinfo">
              <img src={skype} alt="skype" width="20" />
            </a>
          </div>
          <div className="twitter f__icon">
            <a href="https://www.instagram.com/92logics" target="_blank">
              <img src="/assets/twitter.png" alt="twitter" width="20" />
            </a>
          </div>
          <div className="facebook f__icon">
            <a href="https://www.facebook.com/92logics" target="_blank">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 10.0022C20.0004 8.09104 19.4532 6.2198 18.4231 4.61003C17.393 3.00026 15.9232 1.71938 14.1877 0.919062C12.4522 0.118741 10.5237 -0.167503 8.63053 0.0942223C6.73739 0.355948 4.9589 1.15468 3.50564 2.39585C2.05237 3.63701 0.985206 5.26863 0.430495 7.0975C-0.124217 8.92636 -0.143239 10.8759 0.37568 12.7152C0.894599 14.5546 1.92973 16.2067 3.35849 17.476C4.78726 18.7453 6.54983 19.5786 8.4375 19.8772V12.8922H5.89875V10.0022H8.4375V7.79843C8.38284 7.28399 8.44199 6.76382 8.61078 6.2748C8.77957 5.78577 9.05386 5.33986 9.4142 4.96866C9.77455 4.59746 10.2121 4.31007 10.6959 4.12684C11.1797 3.94362 11.6979 3.86905 12.2137 3.90843C12.9638 3.91828 13.7121 3.98346 14.4525 4.10343V6.56718H13.1925C12.9779 6.53911 12.7597 6.55967 12.554 6.62733C12.3484 6.69498 12.1607 6.80801 12.0046 6.95804C11.8486 7.10807 11.7283 7.29127 11.6526 7.49408C11.577 7.69689 11.5479 7.91411 11.5675 8.12968V10.0047H14.3412L13.8975 12.8947H11.5625V19.8834C13.9153 19.5112 16.058 18.3114 17.6048 16.4999C19.1516 14.6884 20.001 12.3842 20 10.0022Z"></path>
              </svg>
            </a>
          </div>
          <div className="linedIn f__icon">
            <a href="https://www.linkedin.com/company/92logics" target="_blank">
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M19.125 0H0.875C0.375 0 0 0.375 0 0.875V19.25C0 19.625 0.375 20 0.875 20H19.25C19.75 20 20.125 19.625 20.125 19.125V0.875C20 0.375 19.625 0 19.125 0ZM5.875 17H3V7.5H6V17H5.875ZM4.5 6.25C3.5 6.25 2.75 5.375 2.75 4.5C2.75 3.5 3.5 2.75 4.5 2.75C5.5 2.75 6.25 3.5 6.25 4.5C6.125 5.375 5.375 6.25 4.5 6.25ZM17 17H14V12.375C14 11.25 14 9.875 12.5 9.875C11 9.875 10.75 11.125 10.75 12.375V17.125H7.75V7.5H10.625V8.75C11 8 12 7.25 13.375 7.25C16.375 7.25 16.875 9.25 16.875 11.75V17H17Z"></path>
              </svg>
            </a>
          </div>
          <div className="linedIn f__icon">
            <a href="https://www.upwork.com/ag/92logics" target="_blank">
              <img src="/assets/upwork.png" alt="upwork" width="20" />
            </a>
          </div>
          <div className="linedIn f__icon">
            <a href="https://www.pinterest.com/92LogicsPK" target="_blank">
              <img src="/assets/pinterest.png" alt="pinterest" width="20" />
            </a>
          </div>
          <div className="linedIn f__icon">
            <a href="https://wa.me/+923306669292" target="_blank">
              <img src="/assets/whatsapp.png" alt="whatsapp" width="20" />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
