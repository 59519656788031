import React from "react";
import "../css/dotNetDevelopersAvailable.css";

const SqlServerDotNetDevelopersAvailable = () => {
  const employeeData = [
    {
      id: 1,
      name: "Anser Mehmood",
      role: "SQL Server Developer",
      message:
        "Anser can be your go-to person for all SQL Server related needs including prototyping, coding, and more.",
      image: "/assets/randomUser.png",
      skills: ["SQL Server", "Database Design"],
    },
    {
      id: 2,
      name: "Adeel Baig",
      role: "SQL Server Developer",
      message:
        "Adeel can be your go-to person for all SQL Server related needs including prototyping, coding, and more.",
      image: "/assets/randomUser.png",
      skills: ["SQL Server", "Database Design"],
    },
    {
      id: 3,
      name: "Ahmad Ali",
      role: "SQL Server Developer",
      message:
        "Ahmad can be your go-to person for all SQL Server related needs including prototyping, coding, and more.",
      image: "/assets/randomUser.png",
      skills: ["SQL Server", "Database Design"],
    },
    {
      id: 4,
      name: "Muhammad Asad",
      role: "SQL Server Developer",
      message:
        "Asad can be your go-to person for all SQL Server related needs including prototyping, coding, and more.",
      image: "/assets/asad.jpg",
      skills: ["SQL Server", "Database Design"],
    },
  ];

  return (
    <div className="dotNetDevelopersAvailable">
      <div className="dotNetDevelopersAvailable_title">
        <div>
          SQL Server Developers <b>Currently Available</b>
        </div>
        <p className="dotNetDevelopersAvailable_paragraph">
          At 92 Logics ms you will find top talented SQL Server developers,
          experts, and consultants instantly within 48 hours with us. Steer your
          projects into the right direction with our full-stack SQL Server
          developers who are available on hourly, full-time, and part time
          basis.
        </p>
      </div>
      <div className="dotNetDevelopersAvailable_content">
        <div className="dotNetDevelopersAvailable_employees">
          {employeeData.map((employee) => (
            <div
              className="dotNetDevelopersAvailable_single_emp"
              key={employee.id}
            >
              <div className="dotNetDevelopersAvailable_single_emp_img">
                <img src={employee.image} alt={employee.name} />
              </div>
              <h2>{employee.name}</h2>
              <p>{employee.role}</p>
              <div className="span">{employee.message}</div>
              <div className="dotNetDevelopersAvailable_single_emp_skills_list">
                {employee.skills.map((skill, index) => (
                  <span key={index}>{skill}</span>
                ))}
              </div>
              <div className="dotNetDevelopersAvailable_employee_hire_btn">
                <a href="#">Hire Now</a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SqlServerDotNetDevelopersAvailable;
